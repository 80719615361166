<template>
    <div class="p-8 text-xs">
        <button @click="$router.push('/monitor')" class="flex items-center text-blue-500">
            <!-- Font Awesome for "chevron-left" -->
            <i class="fas fa-chevron-left w-6 h-6 mb-2"></i>
            <span class="ml-2 mb-5">Kembali</span>
        </button>
        <div class="">
            <div class="">

                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 md:col-span-3 rounded-2xl bg-white p-4 flex items-center">
                        <div class="p-4 rounded-full bg-blue-50 flex flex-wrap justify-center mr-4">
                            <unicon width="20px" height="20px" name="cloud-showers-heavy" fill="#3B82F6"></unicon>
                        </div>
                        <div class="text-lg font-bold">
                            <span v-if="currentDevice.precipitation >= 0.1">Hujan</span>
                            <span v-else>Cerah</span>
                            <p class="text-gray-500 text-xs font-normal">Cuaca</p>
                        </div>
                    </div>

                    <div class="col-span-12 md:col-span-3 rounded-2xl bg-white p-4 flex items-center">
                        <div class="flex flex-wrap justify-center items-center rounded-full bg-green-50 px-4 py-5 mr-4"
                            v-if="currentDevice.persentase_battery >= 80">
                            <img src="/img/100.svg" alt="">
                        </div>
                        <div class="flex flex-wrap justify-center items-center rounded-full bg-green-50 px-4 py-5 mr-4"
                            v-else-if="currentDevice.persentase_battery < 80 && currentDevice.persentase_battery > 50">
                            <img src="/img/75.svg" alt="">
                        </div>
                        <div class="flex flex-wrap justify-center items-center rounded-full bg-yellow-50 px-4 py-5 mr-4"
                            v-else-if="currentDevice.persentase_battery <= 50 && currentDevice.persentase_battery >= 40">
                            <img src="/img/50.svg" alt="">

                        </div>
                        <div class="flex flex-wrap justify-center items-center rounded-full bg-red-50 px-4 py-5 mr-4"
                            v-else-if="currentDevice.persentase_battery < 40 && currentDevice.persentase_battery > 0">
                            <img src="/img/25.svg" alt="">

                        </div>
                        <div class="flex flex-wrap justify-center items-center rounded-full bg-red-50 px-4 py-5 mr-4"
                            v-else-if="currentDevice.persentase_battery <= 0">
                            <img src="/img/0.svg" alt="">

                        </div>
                        <div>
                            <p class="text-lg font-bold">{{ currentDevice.persentase_battery }}%</p>
                            <p class="text-gray-500">Baterai</p>
                        </div>
                    </div>

                    <div class="col-span-12 md:col-span-3 rounded-2xl bg-white p-4 flex items-center">
                        <div class="p-4 rounded-full bg-blue-50 flex flex-wrap justify-center mr-4">
                            <unicon width="20px" height="20px" name="brightness" fill="#3B82F6"></unicon>
                        </div>
                        <div>
                            <p class="text-lg font-bold">{{ currentDevice.VSOL }} V</p>
                            <p class="text-gray-500">Voltase Solar Panel</p>
                        </div>
                    </div>

                    <div class="col-span-12 md:col-span-3 rounded-2xl bg-white p-4 flex items-center">
                        <div class="p-4 rounded-full bg-blue-50 flex flex-wrap justify-center mr-4">
                            <unicon :name="windIcon" width="20px" height="20px" fill="black"></unicon>
                        </div>
                        <div>
                            <p class="text-lg font-bold">{{ windDirectionLabel }}</p>
                            <p class="text-gray-500">Arah Angin</p>
                        </div>
                    </div>
                </div>

                <div class="mt-4 mb-2">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12  md:col-span-8 rounded-2xl rounded bg-white z-30 md:h-full">
                            <map-component class="rounded-2xl" :device="device" v-if="device.length" />
                        </div>
                        <div class="col-span-12 md:col-span-4 rounded-2xl p-6 bg-white">
                            <div class="">
                                <span class="text-lg font-bold">{{ currentDevice.name }}</span>
                                <br>
                                <span class="text-gray-500">{{ currentDevice.code }}</span>
                            </div>
                            <div @click="dialog = true"
                                class="mt-4 cursor-pointer rounded-lg text-sm font-semibold text-center py-2 text-white bg-blue-500">
                                Ganti Perangkat
                            </div>

                            <hr class="mt-4">

                            <div class="flex items-center text-sm mt-4">
                                <unicon width="20px" height="20px" name="location-point" fill="#9CA3AF" class="mr-2">
                                </unicon>
                                <a class="text-gray-500"
                                    :href="`https://www.google.com/maps/place/${currentDevice.latitude} ${currentDevice.longitude}`"
                                    target="_blank" rel="noopener noreferrer">
                                    {{ currentDevice.latitude }} {{ currentDevice.longitude }}
                                </a>
                            </div>
                            <hr class="mt-4">

                            <div class="flex items-center text-sm mt-4 text-gray-500">
                                <unicon width="20px" height="20px" name="calendar-alt" fill="#9CA3AF" class="mr-2">
                                </unicon>
                                {{ parseDate(currentDevice.last_update, 'YYYY-MM-DD HH:mm:ss') }}
                            </div>
                            <hr class="mt-4">
                            <div class="flex items-center text-sm mt-4 text-gray-500">
                                <unicon width="20px" height="20px" name="sim-card" fill="#9CA3AF" class="mr-2"></unicon>
                                {{ dataChart.provider_name }}
                            </div>
                            <hr class="mt-4">
                            <div class="flex items-center text-sm mt-4 text-gray-500">
                                <unicon width="20px" height="20px" name="signal" fill="#9CA3AF" class="mr-2"></unicon>
                                <!-- {{parseFloat(currentDevice.CSQ).toFixed(0)}} -->
                                <div v-if="currentDevice.CSQ >= 41 && currentDevice.CSQ <= 60"
                                    class="flex items-center">
                                    <span class="text-xs mr-2">Good</span>
                                </div>
                                <div v-if="currentDevice.CSQ >= 21 && currentDevice.CSQ <= 40"
                                    class="flex items-center">
                                    <span class="text-xs mr-2">Medium</span>
                                </div>
                                <div v-if="currentDevice.CSQ >= 0 && currentDevice.CSQ <= 20" class="flex items-center">
                                    <span class="text-xs mr-2">Low</span>
                                </div>

                            </div>
                            <hr class="mt-4">
                            <div class="flex items-center text-sm mt-4 text-gray-500">
                                <unicon width="20px" height="20px" name="circuit" fill="#9CA3AF" class="mr-2"></unicon>
                                {{ currentDevice.tipe_sensor }}
                            </div>

                        </div>

                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Intensitas Hujan</p>
                                <span class="text-gray-500 text-xs">{{
                                    parseFloat(currentDevice.precipitation).toFixed(2) }}
                                    mm Intensitas Hujan pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="ph"></div>
                            </div>
                        </div>


                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Pancaran Sinar Matahari</p>
                                <span class="text-gray-500 text-xs">{{ parseFloat(currentDevice.solar).toFixed(0) }}
                                    W/m2
                                    Pancaran Sinar Matahari pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="solar"></div>
                            </div>
                        </div>

                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Jarak Sambaran Petir</p>
                                <span class="text-gray-500 text-xs">{{
                                    parseFloat(currentDevice.strikeDistance).toFixed(0) }}
                                    km Jarak Sambaran Petir pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="strikeDistance"></div>
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Intensitas Petir</p>
                                <span class="text-gray-500 text-xs">{{
                                    parseFloat(currentDevice.strikes).toFixed(0) }}
                                    km Intensitas Petir pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="strikes"></div>
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Tekanan Uap</p>
                                <span class="text-gray-500 text-xs">{{
                                    parseFloat(currentDevice.vaporPressure).toFixed(0) }}
                                    kPa Tekanan Uap pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="vaporPressure"></div>
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Kecepatan Angin</p>
                                <span class="text-gray-500 text-xs">{{ parseFloat(currentDevice.windSpeed).toFixed(0) }}
                                    km/h Kecepatan Angin pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="windSpeed"></div>
                            </div>
                        </div>


                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Arah Angin</p>
                                <span class="text-gray-500 text-xs">{{
                                    parseFloat(currentDevice.windDirection).toFixed(2) }}
                                    deg N Arah Angin pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="windDirection"></div>
                            </div>
                        </div>

                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Kecepatan Hembusan Angin</p>
                                <span class="text-gray-500 text-xs">{{
                                    parseFloat(currentDevice.gustWindSpeed).toFixed(2) }}
                                    m/s Kecepatan Hembusan Angin pukul {{ parseDate(currentDevice.last_update, 'HH.mm')
                                    }}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="gustWindSpeed"></div>
                            </div>
                        </div>
                        <div v-if="isUserAdmin" class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Hembusan Angin Utara</p>
                                <span class="text-gray-500 text-xs">{{
                                    parseFloat(currentDevice.gustNorthWindSpeed).toFixed(2) }}
                                    km/h Hembusan Angin Utara pukul {{ parseDate(currentDevice.last_update, 'HH.mm')
                                    }}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="gustNorthWindSpeed"></div>
                            </div>
                        </div>
                        <div v-if="isUserAdmin" class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Hembusan Angin Timur</p>
                                <span class="text-gray-500 text-xs">{{
                                    parseFloat(currentDevice.gustEastWindSpeed).toFixed(2) }}
                                    km/h Hembusan Angin Timur pukul {{ parseDate(currentDevice.last_update, 'HH.mm')
                                    }}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="gustEastWindSpeed"></div>
                            </div>
                        </div>
                        <div v-if="isUserAdmin" class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Kecepatan Angin Utara</p>
                                <span class="text-gray-500 text-xs">{{
                                    parseFloat(currentDevice.NorthWindSpeed).toFixed(2) }}
                                    km/h Kecepatan Angin Utara pukul {{ parseDate(currentDevice.last_update, 'HH.mm')
                                    }}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="NorthWindSpeed"></div>
                            </div>
                        </div>

                        <div v-if="isUserAdmin" class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Kecepatan Angin Timur</p>
                                <span class="text-gray-500 text-xs">{{
                                    parseFloat(currentDevice.EastWindSpeed).toFixed(2) }}
                                    km/h Kecepatan Angin Timur pukul {{ parseDate(currentDevice.last_update, 'HH.mm')
                                    }}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="EastWindSpeed"></div>
                            </div>
                        </div>
                        <div v-if="isUserAdmin" class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Kemiringan PTM pada sumbu X</p>
                                <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.xOrientation).toFixed(2) }}
                                    Xinc Kemiringan PTM pada sumbu X pukul {{ parseDate(currentDevice.last_update, 'HH.mm')}}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="xOrientation"></div>
                            </div>
                        </div>
                        <div v-if="isUserAdmin" class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Kemiringan PTM pada sumbu Y</p>
                                <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.yOrientation).toFixed(2) }}
                                    Xinc Kemiringan PTM pada sumbu Y pukul {{ parseDate(currentDevice.last_update, 'HH.mm')}}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="yOrientation"></div>
                            </div>
                        </div>


                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Suhu Udara</p>
                                <span class="text-gray-500 text-xs">{{
                                    parseFloat(currentDevice.airTemperature).toFixed(0) }}
                                    °C pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="airTemperature"></div>
                            </div>
                        </div>

                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Sinyal GSM</p>
                                <span class="text-gray-500 text-xs">{{ parseFloat(currentDevice.CSQ).toFixed(0) }}
                                    sinyal
                                    GSM pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }} WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="signal"></div>
                            </div>
                        </div>

                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Voltase Solar Panel</p>
                                <span class="text-gray-500 text-xs">{{ parseFloat(currentDevice.VSOL) }} V voltase solar
                                    panel pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }} WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="vsol"></div>
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Kelembapan</p>
                                <span class="text-gray-500 text-xs">{{ parseFloat(currentDevice.HUM).toFixed(0) }} %
                                    kelembapan pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }} WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="humidity"></div>
                            </div>
                        </div>

                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Tekanan Atmosfir</p>
                                <span class="text-gray-500 text-xs">{{ parseFloat(currentDevice.PATM).toFixed(0) }} kPa
                                    tekanan atmosfer pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                    WIB</span>
                            </div>
                            <div class="p-4">
                                <div id="atmosfer"></div>
                            </div>
                        </div>

                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <p class="font-bold text-base">Baterai</p>
                                <span class="text-gray-500 text-xs">{{ parseFloat(currentDevice.VBAT).toFixed(2) }} VBAT
                                    baterai pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }} WIB</span>
                            </div>

                            <div class="p-4">
                                <div id="batre"></div>
                            </div>
                        </div>


                    </div>
                </div>

                <div id="form_lamaran" v-if="dialog" class="absolute" style="z-index:110000 !important">
                    <div class="fixed inset-0 z-50 opacity-50 bg-black"></div>
                    <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
                        <div class="bg-white w-full md:w-3/4">
                            <div class="bg-gray-100 p-4 rounded rounded-b-none flex justify-between items-center">
                                <p>Data Perangkat</p>
                                <i class="far fa-times-square text-lg cursor-pointer" @click="closeDialog"></i>
                            </div>
                            <div class="bg-white w-full rounded rounded-t-none p-4 overflow-auto max-h-screen">
                                <div style="w-full">
                                    <div class="w-full">
                                        <device-component class="w-full" v-on:close="closeDialog" />
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <!-- <audio style="display: none;" id="backMusic">
                <source :src="require('../../assets/emergency.mp3')" type="audio/mpeg">
            </audio> -->

    </div>

</template>
<script>
var chartSignal, chartBatre, chartVsol, chartHumidity, chartAtmosfer, chartPh, chartSolar, chartStrikeDistance, chatVaporPressure, chartStrikes, chartWindSpeed, chartWindDirection, chartGustWindSpeed, chartAirTemperature, chartNorthWindSpeed, chartEastWindSpeed, chartgustNorthWindSpeed, chartgustEastWindSpeed, chartxOrientation, chartyOrientation
import { Carousel, Slide } from 'vue-carousel';
import { mapActions, mapGetters } from 'vuex';
import deviceComponent from '../../components/global/device.vue'
import mapComponent from '../../components/singleMap.vue'
import ApexCharts from 'apexcharts'
import moment from 'moment'
import optionPh from './optionRainSensor/optionRainfall'
// import optionSolar from './optionRainSensor/optionSolar'
import phComponent from './component/ph.vue'
import optionBatre from './optionBatre'
import optionSignal from './optionSignal'
import optionVsol from './optionVsol'
import optionHumidity from './optionHumidity'
import optionAtmosfer from './optionAtmosfer'
import globalMixin from '../../mixin/global'
export default {
    data() {
        return {
            hours: 0,
            minutes: 0,
            seconds: 0,
            dialog: false,
            loadingChart: false,
            update: '',
            dataPh: [],
            dataChart: [],
            batre: [],
            dataLevel: [],
            dataSignal: [],
            dataVsol: [],
            dataBatre: [],
            dataHumidity: [],
            dataAtmosfer: [],
            dataSolar: [],
            optionsSolar: {},

            dataStrikeDistance: [],
            optionsStrikeDistance: {},

            dataVaporPressure: [],
            optionsVaporPressure: {},

            dataStrikes: [],
            optionsStrikes: {},

            dataWindSpeed: [],
            optionsWindSpeed: {},

            dataWindDirection: [],
            optionsWindDirection: {},

            dataGustWindSpeed: [],
            optionsGustWindSpeed: {},

            xOrientation: [],
            optionsxOrientation: {},

            yOrientation: [],
            optionsyOrientation: {},

            NorthWindSpeed: [],
            optionsNorthWindSpeed: {},

            EastWindSpeed: [],
            optionsEastWindSpeed: {},

            datagustNorthWindSpeed: [],
            optionsgustNorthWindSpeed: {},

            datagustEastWindSpeed: [],
            optionsgustEastWindSpeed: {},

            dataAirTemperature: [],
            optionsAirTemperature: {},

            mute: true,
            audio2: null,
            dialog_danger: false,
            first_danger: true
        }
    },
    components: {
        Carousel,
        Slide,
        deviceComponent, mapComponent, phComponent

    },
    mixins: [globalMixin, optionBatre, optionSignal,
        optionVsol, optionHumidity, optionAtmosfer, optionPh],
    methods: {
        ...mapActions({
            setCurrentDevice: 'device/setCurrentDevice',
        }),
        async setDevice(item) {
            this.first_danger = true
            await this.setCurrentDevice(item)
        },
        setTime() {
            setInterval(() => {
                const date = new Date()
                this.hours = date.getHours()
                this.minutes = this.checkSingleDigit(date.getMinutes())
                this.seconds = this.checkSingleDigit(date.getSeconds())
            }, 1000)
        },
        closeDialog() {
            this.dialog = false
        },
        checkSingleDigit(digit) {
            return ('0' + digit).slice(-2)
        },
        async getData() {
            await this.axios.get('v2/device/detail/data/' + this.currentDevice.id, this.config)
                .then((ress) => {
                    console.log(ress)
                    this.dataChart = ress.data

                })
                .catch((err) => {
                    this.errorResponse(err)
                })
        },
        async pushData(x) {
            let label = x.last_update
            await this.dataPh.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.precipitation).toFixed(4)
            })

            await this.dataSolar.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.solar).toFixed(4)
            })
            await this.dataStrikeDistance.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.strikeDistance).toFixed(4)
            })

            await this.dataStrikes.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.strikes).toFixed(4)
            })

            await this.dataVaporPressure.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.vaporPressure).toFixed(4)
            })

            await this.dataWindSpeed.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.windSpeed).toFixed(4)
            })

            await this.dataWindDirection.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.windDirection).toFixed(4)
            })
            await this.dataGustWindSpeed.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.gustWindSpeed).toFixed(4)
            })
            await this.xOrientation.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.xOrientation).toFixed(4)
            })
            await this.yOrientation.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.yOrientation).toFixed(4)
            })
            await this.datagustNorthWindSpeed.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.gustNorthWindSpeed).toFixed(4)
            })
            await this.datagustEastWindSpeed.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.gustEastWindSpeed).toFixed(4)
            })
            await this.NorthWindSpeed.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.NorthWindSpeed).toFixed(4)
            })
            await this.EastWindSpeed.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.EastWindSpeed).toFixed(4)
            })

            await this.dataAirTemperature.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.airTemperature).toFixed(4)
            })

            await this.dataVsol.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.VSOL)
            })
            await this.dataHumidity.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.HUM).toFixed(0)
            })
            await this.dataAtmosfer.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.PATM).toFixed(2)
            })
            await this.dataSignal.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.CSQ).toFixed(2)
            })
            await this.batre.push({
                x: new Date(label).getTime(),
                y: 3.85
            })

            await this.dataBatre.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.VBAT)
            })

        },

        async updateChart() {
            this.optionsSolar = JSON.parse(JSON.stringify(this.optionsPh));
            this.optionsStrikeDistance = JSON.parse(JSON.stringify(this.optionsPh));
            this.optionsVaporPressure = JSON.parse(JSON.stringify(this.optionsPh));
            this.optionsStrikes = JSON.parse(JSON.stringify(this.optionsPh));
            this.optionsWindDirection = JSON.parse(JSON.stringify(this.optionsPh));
            this.optionsWindSpeed = JSON.parse(JSON.stringify(this.optionsPh));
            this.optionsGustWindSpeed = JSON.parse(JSON.stringify(this.optionsPh));
            this.optionsgustEastWindSpeed = JSON.parse(JSON.stringify(this.optionsPh));
            this.optionsgustNorthWindSpeed = JSON.parse(JSON.stringify(this.optionsPh));
            this.optionsNorthWindSpeed = JSON.parse(JSON.stringify(this.optionsPh));
            this.optionsEastWindSpeed = JSON.parse(JSON.stringify(this.optionsPh));
            this.optionsxOrientation = JSON.parse(JSON.stringify(this.optionsPh));
            this.optionsyOrientation = JSON.parse(JSON.stringify(this.optionsPh));
            this.optionsAirTemperature = JSON.parse(JSON.stringify(this.optionsPh));
            await this.$set(this.optionsSolar, 'series', [{ name: 'Solar', data: this.dataSolar }])
            await this.$set(this.optionsSolar.tooltip, 'y', {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + ' ' + 'W/m2'
                }
            })

            await this.$set(this.optionsStrikeDistance, 'series', [{ name: 'Strike Distance', data: this.dataStrikeDistance }])
            await this.$set(this.optionsStrikeDistance.tooltip, 'y', {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + ' ' + 'km'
                }
            })

            await this.$set(this.optionsStrikes, 'series', [{ name: '', data: this.dataStrikes }])
            await this.$set(this.optionsStrikes.tooltip, 'y', {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + ' ' + 'strike'
                }
            })

            await this.$set(this.optionsVaporPressure, 'series', [{ name: 'Tekanan Uap', data: this.dataVaporPressure }])
            await this.$set(this.optionsVaporPressure.tooltip, 'y', {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + ' ' + 'kPa'
                }
            })

            await this.$set(this.optionsWindSpeed, 'series', [{ name: 'Wind Speed', data: this.dataWindSpeed }])
            await this.$set(this.optionsWindSpeed.tooltip, 'y', {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + ' ' + 'km/h'
                }
            })

            await this.$set(this.optionsWindDirection, 'series', [{ name: 'Wind Direction', data: this.dataWindDirection }])
            await this.$set(this.optionsWindDirection.tooltip, 'y', {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + ' ' + 'deg N'
                }
            })

            await this.$set(this.optionsGustWindSpeed, 'series', [{ name: 'Gust Wind Speed', data: this.dataGustWindSpeed }])
            await this.$set(this.optionsGustWindSpeed.tooltip, 'y', {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + ' ' + 'm/s'
                }
            })
            await this.$set(this.optionsyOrientation, 'series', [{ name: 'PTM inclination in y-direction', data: this.yOrientation }])
            await this.$set(this.optionsyOrientation.tooltip, 'y', {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + ' ' + 'Xinc'
                }
            })
            await this.$set(this.optionsxOrientation, 'series', [{ name: 'PTM inclination in x-direction', data: this.xOrientation }])
            await this.$set(this.optionsxOrientation.tooltip, 'y', {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + ' ' + 'Xinc'
                }
            })
            await this.$set(this.optionsgustEastWindSpeed, 'series', [{ name: 'Gust Wind Speed', data: this.datagustEastWindSpeed }])
            await this.$set(this.optionsgustEastWindSpeed.tooltip, 'y', {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + ' ' + 'km/h'
                }
            })
            await this.$set(this.optionsgustNorthWindSpeed, 'series', [{ name: 'Gust North Wind Speed', data: this.datagustNorthWindSpeed }])
            await this.$set(this.optionsgustNorthWindSpeed.tooltip, 'y', {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + ' ' + 'm/s'
                }
            })
            await this.$set(this.optionsNorthWindSpeed, 'series', [{ name: 'North Wind Speed', data: this.NorthWindSpeed }])
            await this.$set(this.optionsNorthWindSpeed.tooltip, 'y', {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + ' ' + 'km/h'
                }
            })
            await this.$set(this.optionsEastWindSpeed, 'series', [{ name: 'East Wind Speed', data: this.EastWindSpeed }])
            await this.$set(this.optionsEastWindSpeed.tooltip, 'y', {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + ' ' + 'km/h'
                }
            })

            await this.$set(this.optionsAirTemperature, 'series', [{ name: 'Air Temperature', data: this.dataAirTemperature }])
            await this.$set(this.optionsAirTemperature.tooltip, 'y', {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + ' ' + 'deg C'
                }
            })
            await this.$set(this.optionsPh, 'series', [{ name: 'Rainfall Intensity', data: this.dataPh }])
            await this.$set(this.optionsSignal, 'series', [{ name: 'Sig [-]', data: this.dataSignal }])
            await this.$set(this.optionsVsol, 'series', [{ name: 'Voltase V', data: this.dataVsol }])
            await this.$set(this.optionsHumidity, 'series', [{ name: 'Kelembapan', data: this.dataHumidity }])
            await this.$set(this.optionsAtmosfer, 'series', [{ name: 'Tekanan Atmosfir kPa', data: this.dataAtmosfer }])
            await this.$set(this.optionsBatre, 'series', [
                {
                    name: 'VBAT',
                    type: 'line',
                    data: this.dataBatre
                },
                {
                    name: 'Low Batery',
                    data: this.batre,
                    type: 'area',

                }
            ])
            await this.$set(this.optionsBatre, 'colors', [
                "#1899fb", "#FF1654"
            ])


        },
        // async levelChart(update = '') {
        //     if (!update) {
        //         const elements = [
        //     "#ph", "#signal", "#batre", "#vsol", "#humidity", "#atmosfer",
        //     "#solar", "#strikeDistance", "#strikes", "#vaporPressure",
        //     "#windSpeed", "#windDirection", "#gustWindSpeed", "#gustEastWindSpeed",
        //     "#gustNorthWindSpeed", "#NorthWindSpeed", "#EastWindSpeed", "#airTemperature"
        // ];

        // elements.forEach(selector => {
        //     const element = document.querySelector(selector);
        //     if (element) {
        //         element.innerHTML = '';
        //     } else {
        //         console.warn(`Element not found: ${selector}`);
        //     }
        // });
        //         // document.querySelector("#level").innerHTML = ''
        //         document.querySelector("#ph").innerHTML = ''
        //         document.querySelector("#signal").innerHTML = ''
        //         document.querySelector("#batre").innerHTML = ''
        //         document.querySelector("#vsol").innerHTML = ''
        //         document.querySelector("#humidity").innerHTML = ''
        //         document.querySelector("#atmosfer").innerHTML = ''
        //         document.querySelector("#solar").innerHTML = ''
        //         document.querySelector("#strikeDistance").innerHTML = ''
        //         document.querySelector("#strikes").innerHTML = ''
        //         document.querySelector("#vaporPressure").innerHTML = ''
        //         document.querySelector("#windSpeed").innerHTML = ''
        //         document.querySelector("#windDirection").innerHTML = ''
        //         document.querySelector("#gustWindSpeed").innerHTML = ''
        //         document.querySelector("#gustEastWindSpeed").innerHTML = ''
        //         document.querySelector("#gustNorthWindSpeed").innerHTML = ''
        //         document.querySelector("#NorthWindSpeed").innerHTML = ''
        //         document.querySelector("#EastWindSpeed").innerHTML = ''
        //         document.querySelector("#airTemperature").innerHTML = ''

        //     }
        //     this.batre = this.dataChart.batre
        //     this.dataBatre = this.dataChart.dataBatre
        //     this.dataPh = this.dataChart.precipitation
        //     this.dataSolar = this.dataChart.solar
        //     this.dataStrikeDistance = this.dataChart.strikeDistance
        //     this.dataStrikes = this.dataChart.strikes
        //     this.dataVaporPressure = this.dataChart.vaporPressure
        //     this.dataWindSpeed = this.dataChart.windSpeed
        //     this.dataWindDirection = this.dataChart.windDirection
        //     this.dataGustWindSpeed = this.dataChart.gustWindSpeed
        //     this.datagustEastWindSpeed = this.dataChart.gustEastWindSpeed
        //     this.datagustNorthWindSpeed = this.dataChart.gustNorthWindSpeed
        //     this.NorthWindSpeed = this.dataChart.NorthWindSpeed
        //     this.EastWindSpeed = this.dataChart.EastWindSpeed
        //     this.dataAirTemperature = this.dataChart.airTemperature

        //     this.dataSignal = this.dataChart.dataSignal
        //     this.dataVsol = this.dataChart.dataVsol
        //     this.dataHumidity = this.dataChart.dataHumidity
        //     this.dataAtmosfer = this.dataChart.dataAtmosfer
        //     await this.updateChart()


        //     if (update != '') {


        //     } else {
        //         chartBatre = new ApexCharts(document.querySelector("#batre"), this.optionsBatre);
        //         chartPh = new ApexCharts(document.querySelector("#ph"), this.optionsPh);
        //         chartSignal = new ApexCharts(document.querySelector("#signal"), this.optionsSignal);
        //         chartVsol = new ApexCharts(document.querySelector("#vsol"), this.optionsVsol);
        //         chartHumidity = new ApexCharts(document.querySelector("#humidity"), this.optionsHumidity);
        //         chartAtmosfer = new ApexCharts(document.querySelector("#atmosfer"), this.optionsAtmosfer);
        //         chartSolar = new ApexCharts(document.querySelector("#solar"), this.optionsSolar);
        //         chartStrikeDistance = new ApexCharts(document.querySelector("#strikeDistance"), this.optionsStrikeDistance);
        //         chartStrikes = new ApexCharts(document.querySelector("#strikes"), this.optionsStrikes);
        //         chatVaporPressure = new ApexCharts(document.querySelector("#vaporPressure"), this.optionsVaporPressure);
        //         chartWindSpeed = new ApexCharts(document.querySelector("#windSpeed"), this.optionsWindSpeed);
        //         chartWindDirection = new ApexCharts(document.querySelector("#windDirection"), this.optionsWindDirection);
        //         chartGustWindSpeed = new ApexCharts(document.querySelector("#gustWindSpeed"), this.optionsGustWindSpeed);
        //         chartgustEastWindSpeed = new ApexCharts(document.querySelector("#gustEastWindSpeed"), this.optionsgustEastWindSpeed);
        //         chartgustNorthWindSpeed = new ApexCharts(document.querySelector("#gustNorthWindSpeed"), this.optionsgustNorthWindSpeed);
        //         chartNorthWindSpeed = new ApexCharts(document.querySelector("#NorthWindSpeed"), this.optionsNorthWindSpeed);
        //         chartEastWindSpeed = new ApexCharts(document.querySelector("#EastWindSpeed"), this.optionsEastWindSpeed);
        //         chartAirTemperature = new ApexCharts(document.querySelector("#airTemperature"), this.optionsAirTemperature);


        //         await chartPh.render();
        //         await chartVsol.render();
        //         await chartHumidity.render();
        //         await chartSignal.render();
        //         await chartBatre.render();
        //         await chartAtmosfer.render();
        //         await chartSolar.render();
        //         await chartStrikeDistance.render();
        //         await chartStrikes.render();
        //         await chatVaporPressure.render();

        //         await chartWindSpeed.render();
        //         await chartWindDirection.render();
        //         await chartGustWindSpeed.render();
        //         await chartgustEastWindSpeed.render();
        //         await chartgustNorthWindSpeed.render();
        //         await chartNorthWindSpeed.render();
        //         await chartEastWindSpeed.render();
        //         await chartAirTemperature.render();

        //     }

        //     // let base = await chartSignal.dataURI();
        //     // console.log(base.imgURI)


        // },
        async levelChart(update = '') {
            if (!update) {
                const elements = [
                    "#ph", "#signal", "#batre", "#vsol", "#humidity", "#atmosfer",
                    "#solar", "#strikeDistance", "#strikes", "#vaporPressure",
                    "#windSpeed", "#windDirection", "#gustWindSpeed", "#gustEastWindSpeed",
                    "#gustNorthWindSpeed", "#NorthWindSpeed", "#EastWindSpeed", "#airTemperature", "#xOrientation", "#yOrientation"
                ];

                elements.forEach(selector => {
                    const element = document.querySelector(selector);
                    if (element) {
                        element.innerHTML = '';
                    } else {
                        console.warn(`Element not found: ${selector}`);
                    }
                });
            }

            this.batre = this.dataChart.batre;
            this.dataBatre = this.dataChart.dataBatre;
            this.dataPh = this.dataChart.precipitation;
            this.dataSolar = this.dataChart.solar;
            this.dataStrikeDistance = this.dataChart.strikeDistance;
            this.dataStrikes = this.dataChart.strikes;
            this.dataVaporPressure = this.dataChart.vaporPressure;
            this.dataWindSpeed = this.dataChart.windSpeed;
            this.dataWindDirection = this.dataChart.windDirection;
            this.dataGustWindSpeed = this.dataChart.gustWindSpeed;
            this.datagustEastWindSpeed = this.dataChart.gustEastWindSpeed;
            this.datagustNorthWindSpeed = this.dataChart.gustNorthWindSpeed;
            this.NorthWindSpeed = this.dataChart.NorthWindSpeed;
            this.EastWindSpeed = this.dataChart.EastWindSpeed;
            this.xOrientation = this.dataChart.xOrientation;
            this.yOrientation = this.dataChart.yOrientation;
            this.dataAirTemperature = this.dataChart.airTemperature;

            this.dataSignal = this.dataChart.dataSignal;
            this.dataVsol = this.dataChart.dataVsol;
            this.dataHumidity = this.dataChart.dataHumidity;
            this.dataAtmosfer = this.dataChart.dataAtmosfer;

            await this.updateChart();

            if (update != '') {
                // Update logic for existing charts
            } else {
                // Initialize charts only if elements exist
                const initializeChart = (selector, options) => {
                    const element = document.querySelector(selector);
                    if (element) {
                        return new ApexCharts(element, options);
                    } else {
                        console.warn(`Element not found: ${selector}`);
                        return null;
                    }
                };

                chartBatre = initializeChart("#batre", this.optionsBatre);
                chartPh = initializeChart("#ph", this.optionsPh);
                chartSignal = initializeChart("#signal", this.optionsSignal);
                chartVsol = initializeChart("#vsol", this.optionsVsol);
                chartHumidity = initializeChart("#humidity", this.optionsHumidity);
                chartAtmosfer = initializeChart("#atmosfer", this.optionsAtmosfer);
                chartSolar = initializeChart("#solar", this.optionsSolar);
                chartStrikeDistance = initializeChart("#strikeDistance", this.optionsStrikeDistance);
                chartStrikes = initializeChart("#strikes", this.optionsStrikes);
                chatVaporPressure = initializeChart("#vaporPressure", this.optionsVaporPressure);
                chartWindSpeed = initializeChart("#windSpeed", this.optionsWindSpeed);
                chartWindDirection = initializeChart("#windDirection", this.optionsWindDirection);
                chartGustWindSpeed = initializeChart("#gustWindSpeed", this.optionsGustWindSpeed);
                chartgustEastWindSpeed = initializeChart("#gustEastWindSpeed", this.optionsgustEastWindSpeed);
                chartgustNorthWindSpeed = initializeChart("#gustNorthWindSpeed", this.optionsgustNorthWindSpeed);
                chartNorthWindSpeed = initializeChart("#NorthWindSpeed", this.optionsNorthWindSpeed);
                chartEastWindSpeed = initializeChart("#EastWindSpeed", this.optionsEastWindSpeed);
                chartxOrientation = initializeChart("#xOrientation", this.optionsxOrientation);
                chartyOrientation = initializeChart("#yOrientation", this.optionsyOrientation);
                chartAirTemperature = initializeChart("#airTemperature", this.optionsAirTemperature);

                // Render charts only if they were initialized
                const charts = [
                    chartPh, chartVsol, chartHumidity, chartSignal, chartBatre, chartAtmosfer,
                    chartSolar, chartStrikeDistance, chartStrikes, chatVaporPressure,
                    chartWindSpeed, chartWindDirection, chartGustWindSpeed, chartgustEastWindSpeed,
                    chartgustNorthWindSpeed, chartNorthWindSpeed, chartEastWindSpeed, chartAirTemperature, 
                    chartxOrientation, chartyOrientation
                ];

                charts.forEach(chart => {
                    if (chart) {
                        chart.render();
                    }
                });
            }
        },

        async createdChart() {

            await this.getData()
            await this.levelChart()
        },

        play() {

            this.audio2.play();
            this.audio2.loop = true;
            this.mute = false;

        },
        stop() {
            this.dialog_danger = false

        }
    },
    computed: {
        ...mapGetters({
            device: 'device/showDevice',
            currentDevice: 'device/currentDevice',
            user: 'auth/user',
        }),
        isUserAdmin() {
            console.log("is admin", this.user)
            return this.user && this.user.role_id === 32;
        },
        windDirectionLabel() {
            const angle = parseFloat(this.currentDevice.windDirection);
            if ((angle >= 0 && angle < 22.5) || (angle >= 337.5 && angle <= 360)) return "Utara";
            if (angle >= 22.5 && angle < 67.5) return "Timur Laut";
            if (angle >= 67.5 && angle < 112.5) return "Timur";
            if (angle >= 112.5 && angle < 157.5) return "Tenggara";
            if (angle >= 157.5 && angle < 202.5) return "Selatan";
            if (angle >= 202.5 && angle < 247.5) return "Barat Daya";
            if (angle >= 247.5 && angle < 292.5) return "Barat";
            if (angle >= 292.5 && angle < 337.5) return "Barat Laut";
            return "Tidak Diketahui";
        },
        windIcon() {
            const angle = parseFloat(this.currentDevice.windDirection);
            if ((angle >= 0 && angle < 22.5) || (angle >= 337.5 && angle <= 360)) return "arrow-down";
            if (angle >= 22.5 && angle < 67.5) return "arrow-down-right";
            if (angle >= 67.5 && angle < 112.5) return "arrow-left";
            if (angle >= 112.5 && angle < 157.5) return "arrow-up-right";
            if (angle >= 157.5 && angle < 202.5) return "arrow-up";
            if (angle >= 202.5 && angle < 247.5) return "arrow-up-left";
            if (angle >= 247.5 && angle < 292.5) return "arrow-right";
            if (angle >= 292.5 && angle < 337.5) return "arrow-down-left";
            return null;
        },
    },
    async created() {
        if (!this.currentDevice.name) {
            this.$router.push('/monitor')
        }
        await this.createdChart(false)
        this.is_admin = this.isUserAdmin;
        // this.update = setInterval(() => {
        //         this.axios.get('v1/device_data/faker/' +this.currentDevice.id )
        //         .then(async ress => {
        //             let data = ress.data.data
        //             await this.getData()
        //             await this.levelChart(true)
        //             this.currentDevice.last_update = data.last_update
        //             this.currentDevice.CSQ = data.CSQ
        //             this.currentDevice.real_ketinggian = data.level
        //             this.currentDevice.VBAT = data.VBAT

        //         })
        //     }, 5000)
    },
    mounted() {
        this.setTime()
        this.audio2 = document.getElementById('backMusic');
        this.sockets.subscribe('updatedatachart', async (data) => {
            console.log(data.id + " " + this.currentDevice.id)
            if (data.id == this.currentDevice.id) {
                this.currentDevice.last_update = data.last_update
                this.currentDevice.CSQ = data.CSQ
                this.currentDevice.precipitation = data.precipitation
                this.currentDevice.VBAT = data.VBAT
                await this.pushData(data)
                await chartPh.updateSeries([{ data: this.dataPh }])
                await chartSignal.updateSeries([{ data: this.dataSignal }])
                await chartVsol.updateSeries([{ data: this.dataVsol }])
                await chartHumidity.updateSeries([{ data: this.dataHumidity }])
                await chartAtmosfer.updateSeries([{ data: this.dataAtmosfer }])
                await chartSolar.updateSeries([{ data: this.dataSolar }])
                await chartStrikeDistance.updateSeries([{ data: this.dataStrikeDistance }])
                await chartStrikes.updateSeries([{ data: this.dataStrikes }])
                await chatVaporPressure.updateSeries([{ data: this.dataVaporPressure }])
                await chartWindSpeed.updateSeries([{ data: this.dataWindSpeed }])
                await chartWindDirection.updateSeries([{ data: this.dataWindDirection }])
                await chartGustWindSpeed.updateSeries([{ data: this.dataGustWindSpeed }])
                await chartgustEastWindSpeed.updateSeries([{ data: this.datagustEastWindSpeed }])
                await chartgustNorthWindSpeed.updateSeries([{ data: this.datagustNorthWindSpeed }])
                await chartNorthWindSpeed.updateSeries([{ data: this.NorthWindSpeed }])
                await chartEastWindSpeed.updateSeries([{ data: this.EastWindSpeed }])
                await chartxOrientation.updateSeries([{ data: this.xOrientation }])
                await chartyOrientation.updateSeries([{ data: this.yOrientation }])
                await chartAirTemperature.updateSeries([{ data: this.dataAirTemperature }])


                chartBatre.updateSeries([
                    {
                        name: 'VBAT',
                        type: 'line',
                        data: this.dataBatre
                    },
                    {
                        name: 'Low Batery',
                        data: this.batre,
                        type: 'area',

                    }
                ])
            }

        });
        // this.play()
    },
    watch: {
        // whenever question changes, this function will run
        currentDevice: async function (newQuestion, oldQuestion) {
            await this.createdChart()
        }
    },
    beforeDestroy() {
        // clearInterval(this.update)
        this.sockets.unsubscribe('updatedatachart');

        clearInterval(this.setTime)
        this.audio2.pause();
        this.mute = true;
    }

}
</script>