<template>
    <div class="p-8 text-xs">
        <button @click="$router.push('/monitor')" class="flex items-center text-blue-500">
            <!-- Font Awesome for "chevron-left" -->
            <i class="fas fa-chevron-left w-6 h-6 mb-2"></i>
            <span class="ml-2 mb-5">Kembali</span>
        </button>
        <div class="">
            <div class="">
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 md:col-span-4  rounded-2xl bg-white p-4 flex items-center">
                        <div class="rounded-full bg-red-50 flex flex-wrap justify-center items-center mr-4"
                            v-if="currentDevice.siaga_level == 1">
                            <img src="/img/ruler_siaga1.svg" alt="" srcset="">
                        </div>
                        <div class="rounded-full bg-yellow-50 flex flex-wrap justify-center items-center mr-4 p-3"
                            v-else-if="currentDevice.siaga_level == 2">
                            <img src="/img/ruler_siaga2.svg" alt="" srcset="">
                        </div>
                        <div class="rounded-full bg-blue-50 flex flex-wrap justify-center items-center mr-4  p-3"
                            v-else-if="currentDevice.siaga_level == 3">
                            <img src="/img/ruler_siaga3.svg" alt="" srcset="">
                        </div>
                        <div class="rounded-full bg-green-50 flex flex-wrap justify-center items-center mr-4 p-3"
                            v-else-if="currentDevice.siaga_level == 4">
                            <img src="/img/ruler_siaga4.svg" alt="" srcset="">
                        </div>
                        <div>
                            <textLevel :item="currentDevice" class="text-lg" />
                            <!-- <p class="text-gray-500 bg-white-50">Ketinggian Air</p> -->
                            <p class="bg-white-50">Ketinggian Air</p>
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-4  rounded-2xl bg-white p-4 flex items-center">
                        <div class="flex flex-wrap justify-center items-center rounded-full bg-green-50 px-4 py-5 mr-4"
                            v-if="currentDevice.persentase_battery >= 80">
                            <img src="/img/100.svg" alt="">
                        </div>
                        <div class="flex flex-wrap justify-center items-center rounded-full bg-green-50 px-4 py-5 mr-4"
                            v-else-if="currentDevice.persentase_battery < 80 && currentDevice.persentase_battery > 50">
                            <img src="/img/75.svg" alt="">
                        </div>
                        <div class="flex flex-wrap justify-center items-center rounded-full bg-yellow-50 px-4 py-5 mr-4"
                            v-else-if="currentDevice.persentase_battery <= 50 && currentDevice.persentase_battery >= 40">
                            <img src="/img/50.svg" alt="">

                        </div>
                        <div class="flex flex-wrap justify-center items-center rounded-full bg-red-50 px-4 py-5 mr-4"
                            v-else-if="currentDevice.persentase_battery < 40 && currentDevice.persentase_battery > 0">
                            <img src="/img/25.svg" alt="">

                        </div>
                        <div class="flex flex-wrap justify-center items-center rounded-full bg-red-50 px-4 py-5 mr-4"
                            v-else-if="currentDevice.persentase_battery <= 0">
                            <img src="/img/0.svg" alt="">

                        </div>
                        <div>
                            <p class="text-lg font-bold">{{ currentDevice.persentase_battery }}%</p>
                            <!-- <p class="text-gray-500">Baterai</p> -->
                            <p class="bg-white-50">Baterai</p>
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-4  rounded-2xl bg-white p-4 flex items-center">
                        <div class="p-4 rounded-full bg-blue-50 flex flex-wrap justify-center mr-4">
                            <unicon width="20px" height="20px" name="brightness" fill="#3B82F6"></unicon>
                        </div>
                        <div>
                            <p class="text-lg font-bold">{{ currentDevice.VSOL }} V</p>
                            <!-- <p class="text-gray-500">Voltase Solar Panel</p> -->
                            <p class="bg-white-50">Voltase Solar Panel</p>
                        </div>
                    </div>
                </div>
                <div class="mt-4 mb-2">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12  md:col-span-8 rounded bg-white-500 z-30 md:h-full">
                            <map-component class="rounded-2xl" :device="device" v-if="device.length" />
                        </div>
                        <div class="col-span-12 md:col-span-4 rounded-2xl p-6 bg-white">
                            <div class="">
                                <span class="text-lg font-bold">{{ currentDevice.name }}</span>
                                <br>
                                <!-- <span class="text-gray-500">{{currentDevice.code}}</span> -->
                                <span class="bg-white-50">{{ currentDevice.code }}</span>
                            </div>
                            <div @click="dialog = true"
                                class="mt-4 cursor-pointer rounded-lg text-sm font-semibold text-center py-2 text-white bg-blue-500 hover:bg-blue-600">
                                Ganti Perangkat
                            </div>

                            <hr class="mt-4">

                            <div class="flex items-center text-sm mt-4">
                                <unicon width="20px" height="20px" name="location-point" fill="#9CA3AF"
                                    class="mr-2 bg-white"></unicon>
                                <!-- <a class="text-gray-500" :href="`https://www.google.com/maps/place/${currentDevice.latitude} ${currentDevice.longitude}`" target="_blank" rel="noopener noreferrer"> -->
                                <a class="bg-white-50"
                                    :href="`https://www.google.com/maps/place/${currentDevice.latitude} ${currentDevice.longitude}`"
                                    target="_blank" rel="noopener noreferrer">
                                    {{ currentDevice.latitude }} {{ currentDevice.longitude }}
                                </a>
                            </div>
                            <hr class="mt-4">

                            <!-- <div class="flex items-center text-sm mt-4 text-gray-500"> -->
                            <div class="flex items-center text-sm mt-4 bg-white-500">
                                <unicon width="20px" height="20px" name="calendar-alt" fill="#9CA3AF" class="mr-2">
                                </unicon>
                                <!-- {{parseDate(currentDevice.last_update,'YYYY-MM-DD HH:mm:ss')}} -->
                                {{ parseDate(currentDevice.last_update, 'DD-MM-Y HH:mm:ss') }}
                            </div>
                            <hr class="mt-4">
                            <!-- <div class="flex items-center text-sm mt-4 text-gray-500"> -->
                            <div class="flex items-center text-sm mt-4 bg-white-50">
                                <unicon width="20px" height="20px" name="sim-card" fill="#9CA3AF" class="mr-2"></unicon>
                                {{ dataChart.provider_name }}
                            </div>
                            <hr class="mt-4">
                            <!-- <div class="flex items-center text-sm mt-4 text-gray-500"> -->
                            <div class="flex items-center text-sm mt-4 bg-white-50">
                                <unicon width="20px" height="20px" name="signal" fill="#9CA3AF" class="mr-2"></unicon>
                                {{ parseFloat(currentDevice.CSQ).toFixed(0) }} Sig[-]

                            </div>
                            <hr class="mt-4">
                            <!-- <div class="flex items-center text-sm mt-4 text-gray-500"> -->
                            <div class="flex items-center text-sm mt-4 bg-white-50">
                                <unicon width="20px" height="20px" name="circuit" fill="#9CA3AF" class="mr-2"></unicon>
                                {{ currentDevice.tipe_sensor }}
                            </div>

                        </div>
                        <div v-if="['PTM5360', 'PTM6022', 'PTM0001', 'PTM6022', 'PTM5311'].includes(currentDevice.code)"
                            class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <div class="flex justify-between w-full">
                                    <div class="">
                                        <p class="font-bold text-base">Maximum wave height</p>
                                        <!-- <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.real_ketinggian * 100).toFixed(2)}} m maximum eave height pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span> -->
                                        <span class="bg-white text-xs">{{ parseFloat(currentDevice.real_ketinggian *
                                            100).toFixed(2) }} m maximum eave height pukul
                                            {{ parseDate(currentDevice.last_update, 'HH.mm') }} WIB</span>
                                    </div>
                                    <div class="group inline-block relative cursor-pointer"
                                        v-click-outside="closeDialogFilterRealKetinggian">
                                        <div class="flex flex-wrap content-center items-center">
                                            <button class="flex flex-wrap conent-center items-end justify-end"
                                                @click="dialog_filter_real_ketinggian = !dialog_filter_real_ketinggian">
                                                <div class="">
                                                    <div
                                                        class="flex items-center rounded-lg border border-gray-100 px-4 py-2">
                                                        <span v-if="filter_real_ketinggian == 'last_day'">
                                                            Hari Ini
                                                        </span>
                                                        <span v-if="filter_real_ketinggian == '3days'">
                                                            3 Hari Terakhir
                                                        </span>
                                                        <span v-if="filter_real_ketinggian == 'last_week'">
                                                            1 Minggu Terakhir
                                                        </span>
                                                        <span v-if="filter_real_ketinggian == 'last_month'">
                                                            1 Bulan Terakhir
                                                        </span>
                                                        <span v-if="filter_real_ketinggian == '3months'">
                                                            3 Bulan Terakhir
                                                        </span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="shadow-lg rounded-2xl transform scale-100 text-black absolute
                                        transition duration-150 ease-in-out origin-top w-full bg-white flex-none"
                                            v-show="dialog_filter_real_ketinggian"
                                            style="z-index:1000000!important; min-width:150px">
                                            <div class=" max-h-56 overflow-auto py-4 text-gray-400 w-full">
                                                <div class="hover:bg-gray-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_day', 'real_ketinggian')">
                                                    <div class="ml-4 ">Hari Ini</div>
                                                </div>
                                                <div class="hover:bg-gray-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3days', 'real_ketinggian')">
                                                    <div class="ml-4 ">3 Hari Terakhir</div>
                                                </div>
                                                <div class="hover:bg-gray-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_week', 'real_ketinggian')">
                                                    <div class="ml-4 ">1 Minggu Terakhir</div>
                                                </div>
                                                <div class="hover:bg-gray-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_month', 'real_ketinggian')">
                                                    <div class="ml-4 ">1 Bulan Terakhir</div>
                                                </div>
                                                <div class="hover:bg-gray-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3months', 'real_ketinggian')">
                                                    <div class="ml-4 ">3 Bulan Terakhir</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-4">
                                <div style="width: 100%; height: 300px;" id="chartdiv_wave"></div>
                            </div>
                        </div>
                        <div v-if="['PTM5360', 'PTM6022', 'PTM0001', 'PTM6022', 'PTM5311'].includes(currentDevice.code)"
                            class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <div class="flex justify-between w-full">
                                    <div class="">
                                        <p class="font-bold text-base">Mean wave direction</p>
                                        <span class="text-gray-500 text-xs">{{ parseFloat(currentDevice.real_ketinggian
                                            *
                                            100).toFixed(2)}} deg mean wave direction pukul
                                            {{ parseDate(currentDevice.last_update, 'HH.mm') }} WIB</span>
                                    </div>
                                    <div class="group inline-block relative cursor-pointer"
                                        v-click-outside="closeDialogFilterRealKetinggian">
                                        <div class="flex flex-wrap content-center items-center">
                                            <button class="flex flex-wrap conent-center items-end justify-end"
                                                @click="dialog_filter_real_ketinggian = !dialog_filter_real_ketinggian">
                                                <div class="">
                                                    <div
                                                        class="flex items-center rounded-lg border border-gray-100 px-4 py-2">
                                                        <span v-if="filter_real_ketinggian == 'last_day'">
                                                            Hari Ini
                                                        </span>
                                                        <span v-if="filter_real_ketinggian == '3days'">
                                                            3 Hari Terakhir
                                                        </span>
                                                        <span v-if="filter_real_ketinggian == 'last_week'">
                                                            1 Minggu Terakhir
                                                        </span>
                                                        <span v-if="filter_real_ketinggian == 'last_month'">
                                                            1 Bulan Terakhir
                                                        </span>
                                                        <span v-if="filter_real_ketinggian == '3months'">
                                                            3 Bulan Terakhir
                                                        </span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="shadow-lg rounded-2xl transform scale-100 absolute
                                        transition duration-150 ease-in-out origin-top w-full bg-white flex-none"
                                            v-show="dialog_filter_real_ketinggian"
                                            style="z-index:1000000!important; min-width:150px">
                                            <div class=" max-h-56 overflow-auto py-4 bg-white w-full">
                                                <div class="hover:bg-gray-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_day', 'real_ketinggian')">
                                                    <div class="ml-4 ">Hari Ini</div>
                                                </div>
                                                <div class="hover:bg-gray-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3days', 'real_ketinggian')">
                                                    <div class="ml-4 ">3 Hari Terakhir</div>
                                                </div>
                                                <div class="hover:bg-gray-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_week', 'real_ketinggian')">
                                                    <div class="ml-4 ">1 Minggu Terakhir</div>
                                                </div>
                                                <div class="hover:bg-gray-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_month', 'real_ketinggian')">
                                                    <div class="ml-4 ">1 Bulan Terakhir</div>
                                                </div>
                                                <div class="hover:bg-gray-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3months', 'real_ketinggian')">
                                                    <div class="ml-4 ">3 Bulan Terakhir</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-4">
                                <div style="width: 100%; height: 300px;" id="chartdiv_direction"></div>
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <div class="flex justify-between w-full">
                                    <div class="">
                                        <p class="font-bold text-base">Ketinggian Air</p>
                                        <!-- <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.real_ketinggian * 100).toFixed(2)}} cm ketinggian air pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span> -->
                                        <span class="bg-white-50 text-xs">{{ parseFloat(currentDevice.real_ketinggian *
                                            100).toFixed(2) }} cm ketinggian air pukul
                                            {{ parseDate(currentDevice.last_update, 'HH.mm') }} WIB</span>
                                    </div>
                                    <div class="group inline-block relative cursor-pointer"
                                        v-click-outside="closeDialogFilterRealKetinggian">
                                        <div class="flex flex-wrap content-center items-center">
                                            <button class="flex flex-wrap conent-center items-end justify-end"
                                                @click="dialog_filter_real_ketinggian = !dialog_filter_real_ketinggian">
                                                <div class="">
                                                    <div
                                                        class="flex items-center rounded-lg border border-gray-100 px-4 py-2">
                                                        <span v-if="filter_real_ketinggian == 'last_day'">
                                                            Hari Ini
                                                        </span>
                                                        <span v-if="filter_real_ketinggian == '3days'">
                                                            3 Hari Terakhir
                                                        </span>
                                                        <span v-if="filter_real_ketinggian == 'last_week'">
                                                            1 Minggu Terakhir
                                                        </span>
                                                        <span v-if="filter_real_ketinggian == 'last_month'">
                                                            1 Bulan Terakhir
                                                        </span>
                                                        <span v-if="filter_real_ketinggian == '3months'">
                                                            3 Bulan Terakhir
                                                        </span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="shadow-lg rounded-2xl transform scale-100 absolute
                                        transition duration-150 ease-in-out origin-top w-full bg-white flex-none"
                                            v-show="dialog_filter_real_ketinggian"
                                            style="z-index:1000000!important; min-width:150px">
                                            <!-- <div class=" max-h-56 overflow-auto py-4 text-gray-400 w-full"> -->
                                            <div class=" max-h-56 overflow-auto py-4 bg-white w-full">
                                                <div class="hover:bg-white py-2"
                                                    @click="changeDate(currentDevice.id, 'last_day', 'real_ketinggian')">
                                                    <div class="ml-4 ">Hari Ini</div>
                                                </div>
                                                <div class="hover:bg-white-400 py-2"
                                                    @click="changeDate(currentDevice.id, '3days', 'real_ketinggian')">
                                                    <div class="ml-4 ">3 Hari Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-400 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_week', 'real_ketinggian')">
                                                    <div class="ml-4 ">1 Minggu Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-400 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_month', 'real_ketinggian')">
                                                    <div class="ml-4 ">1 Bulan Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-400 py-2"
                                                    @click="changeDate(currentDevice.id, '3months', 'real_ketinggian')">
                                                    <div class="ml-4 ">3 Bulan Terakhir</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-4 bg-white-400">
                                <div style="width: 100%; height: 300px; background-color: white;" id="chartdiv"></div>
                            </div>
                        </div>

                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8 ">
                                <div class="flex justify-between w-full">
                                    <div class="">
                                        <p class="font-bold text-base">Sinyal GSM</p>
                                        <span class="bg-white-500 text-xs">{{ parseFloat(currentDevice.CSQ).toFixed(0)
                                            }}
                                            sinyal GSM pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                            WIB</span>
                                        <!-- <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.CSQ).toFixed(0)}} sinyal GSM pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span> -->
                                    </div>

                                    <div class="group inline-block relative cursor-pointer"
                                        v-click-outside="closeDialogFilterCSQ">
                                        <div class="flex flex-wrap content-center items-center">
                                            <button class="flex flex-wrap conent-center items-end justify-end"
                                                @click="dialog_filter_CSQ = !dialog_filter_CSQ">
                                                <div class="">
                                                    <div
                                                        class="flex items-center rounded-lg border border-gray-100 px-4 py-2">
                                                        <span v-if="filter_CSQ == 'last_day'">
                                                            Hari Ini
                                                        </span>
                                                        <span v-if="filter_CSQ == '3days'">
                                                            3 Hari Terakhir
                                                        </span>
                                                        <span v-if="filter_CSQ == 'last_week'">
                                                            1 Minggu Terakhir
                                                        </span>
                                                        <span v-if="filter_CSQ == 'last_month'">
                                                            1 Bulan Terakhir
                                                        </span>
                                                        <span v-if="filter_CSQ == '3months'">
                                                            3 Bulan Terakhir
                                                        </span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="shadow-lg rounded-2xl transform scale-100 absolute 
                                        transition duration-150 ease-in-out origin-top w-full bg-white flex-none"
                                            v-show="dialog_filter_CSQ"
                                            style="z-index:1000000!important; min-width:150px;">
                                            <!-- <div class=" max-h-56 overflow-auto py-4 text-gray-400 w-full"> -->
                                            <div class=" max-h-56 overflow-auto py-4 bg-white w-full">
                                                <div class="hover:bg-white-400 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_day', 'CSQ')">
                                                    <div class="ml-4 ">Hari Ini</div>
                                                </div>
                                                <div class="hover:bg-white-400 py-2"
                                                    @click="changeDate(currentDevice.id, '3days', 'CSQ')">
                                                    <div class="ml-4 ">3 Hari Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-400 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_week', 'CSQ')">
                                                    <div class="ml-4 ">1 Minggu terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-400 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_month', 'CSQ')">
                                                    <div class="ml-4 ">1 Bulan terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-400 py-2"
                                                    @click="changeDate(currentDevice.id, '3months', 'CSQ')">
                                                    <div class="ml-4 ">3 Bulan terakhir</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-4">
                                <div style="width: 100%; height: 300px; background-color: white;" id="chartdiv_gsm">
                                </div>
                            </div>
                        </div>

                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <div class="flex justify-between w-full">
                                    <div class="">
                                        <p class="font-bold text-base">Kelembapan</p>
                                        <!-- <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.HUM).toFixed(0)}} % kelembapan pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span> -->
                                        <span class="bg-white-500 text-xs">{{ parseFloat(currentDevice.HUM).toFixed(0)
                                            }}
                                            % kelembapan pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                            WIB</span>
                                    </div>
                                    <div class="group inline-block relative cursor-pointer"
                                        v-click-outside="closeDialogFilterHumidity">
                                        <div class="flex flex-wrap content-center items-center">
                                            <button class="flex flex-wrap conent-center items-end justify-end"
                                                @click="dialog_filter_humidity = !dialog_filter_humidity">
                                                <div class="">
                                                    <div
                                                        class="flex items-center rounded-lg border border-gray-100 px-4 py-2">
                                                        <span v-if="filter_humidity == 'last_day'">
                                                            Hari Ini
                                                        </span>
                                                        <span v-if="filter_humidity == '3days'">
                                                            3 Hari Terakhir
                                                        </span>
                                                        <span v-if="filter_humidity == 'last_week'">
                                                            1 Minggu Terakhir
                                                        </span>
                                                        <span v-if="filter_humidity == 'last_month'">
                                                            1 Bulan Terakhir
                                                        </span>
                                                        <span v-if="filter_humidity == '3months'">
                                                            3 Bulan Terakhir
                                                        </span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="shadow-lg rounded-2xl transform scale-100 absolute
                                        transition duration-150 ease-in-out origin-top w-full bg-white flex-none"
                                            v-show="dialog_filter_humidity"
                                            style="z-index:1000000!important; min-width:150px">
                                            <div class=" max-h-56 overflow-auto py-4 bg-white w-full">
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_day', 'HUM')">
                                                    <div class="ml-4 ">Hari Ini</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3days', 'HUM')">
                                                    <div class="ml-4 ">3 Hari Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_week', 'HUM')">
                                                    <div class="ml-4 ">1 Minggu terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_month', 'HUM')">
                                                    <div class="ml-4 ">1 Bulan terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3months', 'HUM')">
                                                    <div class="ml-4 ">3 Bulan terakhir</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-4">
                                <div style="width: 100%; height: 300px; background-color: white;"
                                    id="chartdiv_humidity"></div>
                            </div>
                        </div>

                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <div class="flex justify-between w-full">
                                    <div class="">
                                        <p class="font-bold text-base">Voltase Solar Panel</p>
                                        <!-- <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.VSOL)}} V voltase solar panel pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span> -->
                                        <span class="bg-white-500 text-xs">{{ parseFloat(currentDevice.VSOL) }} V
                                            voltase
                                            solar panel pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                            WIB</span>
                                    </div>

                                    <div class="group inline-block relative cursor-pointer"
                                        v-click-outside="closeDialogFilterVSOL">
                                        <div class="flex flex-wrap content-center items-center">
                                            <button class="flex flex-wrap conent-center items-end justify-end"
                                                @click="dialog_filter_VSOL = !dialog_filter_VSOL">
                                                <div class="">
                                                    <div
                                                        class="flex items-center rounded-lg border border-gray-100 px-4 py-2">
                                                        <span v-if="filter_VSOL == 'last_day'">
                                                            Hari Ini
                                                        </span>
                                                        <span v-if="filter_VSOL == '3days'">
                                                            3 Hari Terakhir
                                                        </span>
                                                        <span v-if="filter_VSOL == 'last_week'">
                                                            1 Minggu Terakhir
                                                        </span>
                                                        <span v-if="filter_VSOL == 'last_month'">
                                                            1 Bulan Terakhir
                                                        </span>
                                                        <span v-if="filter_VSOL == '3months'">
                                                            3 Bulan Terakhir
                                                        </span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="shadow-lg rounded-2xl transform scale-100 absolute
                                        transition duration-150 ease-in-out origin-top w-full bg-white flex-none"
                                            v-show="dialog_filter_VSOL"
                                            style="z-index:1000000!important; min-width:150px">
                                            <div class=" max-h-56 overflow-auto py-4 bg-white w-full">
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_day', 'VSOL')">
                                                    <div class="ml-4 ">Hari Ini</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3days', 'VSOL')">
                                                    <div class="ml-4 ">3 Hari Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_week', 'VSOL')">
                                                    <div class="ml-4 ">1 Minggu terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_month', 'VSOL')">
                                                    <div class="ml-4 ">1 Bulan terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3months', 'VSOL')">
                                                    <div class="ml-4 ">3 Bulan terakhir</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-4">
                                <div style="width: 100%; height: 300px; background-color: white;" id="chartdiv_vsol">
                                </div>
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <div class="flex justify-between w-full">
                                    <div class="">
                                        <p class="font-bold text-base">Elevasi Muka Air</p>
                                        <!-- <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.elevasi_muka_air).toFixed(2)}} m elevasi muka air pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span> -->
                                        <span class="bg-white-500 text-xs">{{
                                            parseFloat(currentDevice.elevasi_muka_air).toFixed(2) }}
                                            m elevasi muka air pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                            WIB</span>
                                    </div>
                                    <div class="group inline-block relative cursor-pointer"
                                        v-click-outside="closeDialogFilterElevasi">
                                        <div class="flex flex-wrap content-center items-center">
                                            <button class="flex flex-wrap conent-center items-end justify-end"
                                                @click="dialog_filter_elevasi_muka_air = !dialog_filter_elevasi_muka_air">
                                                <div class="">
                                                    <div
                                                        class="flex items-center rounded-lg border border-gray-100 px-4 py-2">
                                                        <span v-if="filter_elevasi_muka_air == 'last_day'">
                                                            Hari Ini
                                                        </span>
                                                        <span v-if="filter_elevasi_muka_air == '3days'">
                                                            3 Hari Terakhir
                                                        </span>
                                                        <span v-if="filter_elevasi_muka_air == 'last_week'">
                                                            1 Minggu Terakhir
                                                        </span>
                                                        <span v-if="filter_elevasi_muka_air == 'last_month'">
                                                            1 Bulan Terakhir
                                                        </span>
                                                        <span v-if="filter_elevasi_muka_air == '3months'">
                                                            3 Bulan Terakhir
                                                        </span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="shadow-lg rounded-2xl transform scale-100 absolute
                                        transition duration-150 ease-in-out origin-top w-full bg-white flex-none"
                                            v-show="dialog_filter_elevasi_muka_air"
                                            style="z-index:1000000!important; min-width:150px">
                                            <div class=" max-h-56 overflow-auto py-4 bg-white w-full">
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_day', 'elevasi_muka_air')">
                                                    <div class="ml-4 ">Hari Ini</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3days', 'elevasi_muka_air')">
                                                    <div class="ml-4 ">3 Hari Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_week', 'elevasi_muka_air')">
                                                    <div class="ml-4 ">1 Minggu Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_month', 'elevasi_muka_air')">
                                                    <div class="ml-4 ">1 Bulan Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3months', 'elevasi_muka_air')">
                                                    <div class="ml-4 ">3 Bulan Terakhir</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-4">
                                <div style="width: 100%; height: 300px; background-color: white;" id="chartdiv_elevasi">
                                </div>
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <div class="flex justify-between w-full">
                                    <div class="">
                                        <p class="font-bold text-base">Tekanan Atmosfir</p>
                                        <!-- <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.PATM).toFixed(0)}} kPa tekanan atmosfer pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span> -->
                                        <span class="bg-white-500 text-xs">{{ parseFloat(currentDevice.PATM).toFixed(0)
                                            }}
                                            kPa tekanan atmosfer pukul {{ parseDate(currentDevice.last_update, 'HH.mm')
                                            }}
                                            WIB</span>
                                    </div>
                                    <div class="group inline-block relative cursor-pointer"
                                        v-click-outside="closeDialogFilterTekananAtmosfir">
                                        <div class="flex flex-wrap content-center items-center">
                                            <button class="flex flex-wrap conent-center items-end justify-end"
                                                @click="dialog_filter_tekanan_atmosfir = !dialog_filter_tekanan_atmosfir">
                                                <div class="">
                                                    <div
                                                        class="flex items-center rounded-lg border border-gray-100 px-4 py-2">
                                                        <span v-if="filter_tekanan_atmosfir == 'last_day'">
                                                            Hari Ini
                                                        </span>
                                                        <span v-if="filter_tekanan_atmosfir == '3days'">
                                                            3 Hari Terakhir
                                                        </span>
                                                        <span v-if="filter_tekanan_atmosfir == 'last_week'">
                                                            1 Minggu Terakhir
                                                        </span>
                                                        <span v-if="filter_tekanan_atmosfir == 'last_month'">
                                                            1 Bulan Terakhir
                                                        </span>
                                                        <span v-if="filter_tekanan_atmosfir == '3months'">
                                                            3 Bulan Terakhir
                                                        </span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="shadow-lg rounded-2xl transform scale-100 absolute
                                        transition duration-150 ease-in-out origin-top w-full bg-white flex-none"
                                            v-show="dialog_filter_tekanan_atmosfir"
                                            style="z-index:1000000!important; min-width:150px">
                                            <div class=" max-h-56 overflow-auto py-4 bg-white w-full">
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_day', 'PATM')">
                                                    <div class="ml-4 ">Hari Ini</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3days', 'PATM')">
                                                    <div class="ml-4 ">3 Hari Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_week', 'PATM')">
                                                    <div class="ml-4 ">1 Minggu Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_month', 'PATM')">
                                                    <div class="ml-4 ">1 Bulan Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3months', 'PATM')">
                                                    <div class="ml-4 ">3 Bulan Terakhir</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-4">
                                <div style="width: 100%; height: 300px; background-color: white;"
                                    id="chartdiv_atmosfer"></div>
                            </div>
                        </div>
                        <div v-if="isUserAdmin" class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <div class="flex justify-between w-full">
                                    <div class="">
                                        <p class="font-bold text-base">Kemiringan Pada Sumbu Y</p>
                                        <span class="bg-white-500 text-xs">{{ parseFloat(currentDevice.Ytilt).toFixed(4)
                                            }}
                                            kemiringan pada sumbu y pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                            WIB</span>
                                    </div>
                                    <div class="group inline-block relative cursor-pointer"
                                        v-click-outside="closeDialogFilterYtilt">
                                        <div class="flex flex-wrap content-center items-center">
                                            <button class="flex flex-wrap conent-center items-end justify-end"
                                                @click="dialog_filter_Ytilt = !dialog_filter_Ytilt">
                                                <div class="">
                                                    <div
                                                        class="flex items-center rounded-lg border border-gray-100 px-4 py-2">
                                                        <span v-if="filter_Ytilt == 'last_day'">
                                                            Hari Ini
                                                        </span>
                                                        <span v-if="filter_Ytilt == '3days'">
                                                            3 Hari Terakhir
                                                        </span>
                                                        <span v-if="filter_Ytilt == 'last_week'">
                                                            1 Minggu Terakhir
                                                        </span>
                                                        <span v-if="filter_Ytilt == 'last_month'">
                                                            1 Bulan Terakhir
                                                        </span>
                                                        <span v-if="filter_Ytilt == '3months'">
                                                            3 Bulan Terakhir
                                                        </span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="shadow-lg rounded-2xl transform scale-100 absolute
                                        transition duration-150 ease-in-out origin-top w-full bg-white flex-none"
                                            v-show="dialog_filter_Ytilt"
                                            style="z-index:1000000!important; min-width:150px">
                                            <div class=" max-h-56 overflow-auto py-4 bg-white w-full">
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_day', 'Ytilt')">
                                                    <div class="ml-4 ">Hari Ini</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3days', 'Ytilt')">
                                                    <div class="ml-4 ">3 Hari Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_week', 'Ytilt')">
                                                    <div class="ml-4 ">1 Minggu Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_month', 'Ytilt')">
                                                    <div class="ml-4 ">1 Bulan Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3months', 'Ytilt')">
                                                    <div class="ml-4 ">3 Bulan Terakhir</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-4">
                                <div style="width: 100%; height: 300px; background-color: white;" id="chartdiv_ytilt">
                                </div>
                            </div>
                        </div>
                        <div v-if="isUserAdmin" class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <div class="flex justify-between w-full">
                                    <div class="">
                                        <p class="font-bold text-base">Kemiringan Pada Sumbu X</p>
                                        <span class="bg-white-500 text-xs">
                                            {{ parseFloat(currentDevice.Xtilt).toFixed(4) }} kemiringan pada sumbu y
                                            {{ parseDate(currentDevice.last_update, 'HH.mm') }} WIB
                                        </span>
                                    </div>
                                    <div class="group inline-block relative cursor-pointer"
                                        v-click-outside="closeDialogFilterXtilt">
                                        <div class="flex flex-wrap content-center items-center">
                                            <button class="flex flex-wrap conent-center items-end justify-end"
                                                @click="dialog_filter_Xtilt = !dialog_filter_Xtilt">
                                                <div class="">
                                                    <div
                                                        class="flex items-center rounded-lg border border-gray-100 px-4 py-2">
                                                        <span v-if="filter_Xtilt == 'last_day'">Hari Ini</span>
                                                        <span v-if="filter_Xtilt == '3days'">3 Hari Terakhir</span>
                                                        <span v-if="filter_Xtilt == 'last_week'">1 Minggu
                                                            Terakhir</span>
                                                        <span v-if="filter_Xtilt == 'last_month'">1 Bulan
                                                            Terakhir</span>
                                                        <span v-if="filter_Xtilt == '3months'">3 Bulan Terakhir</span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="shadow-lg rounded-2xl transform scale-100 absolute transition duration-150 ease-in-out origin-top w-full bg-white flex-none"
                                            v-show="dialog_filter_Xtilt"
                                            style="z-index:1000000!important; min-width:150px">
                                            <div class="max-h-56 overflow-auto py-4 bg-white w-full">
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_day', 'Xtilt')">
                                                    <div class="ml-4 ">Hari Ini</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3days', 'Xtilt')">
                                                    <div class="ml-4 ">3 Hari Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_week', 'Xtilt')">
                                                    <div class="ml-4 ">1 Minggu Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_month', 'Xtilt')">
                                                    <div class="ml-4 ">1 Bulan Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3months', 'Xtilt')">
                                                    <div class="ml-4 ">3 Bulan Terakhir</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-4">
                                <div style="width: 100%; height: 300px; background-color: white;" id="chartdiv_xlilt"></div>
                            </div>
                        </div>

                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <div class="flex justify-between w-full">
                                    <div class="">
                                        <p class="font-bold text-base">Debit Air</p>
                                        <!-- <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.debit).toFixed(2)}} m3/dt debiet air pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span> -->
                                        <span class="bg-white-500 text-xs">{{ parseFloat(currentDevice.debit).toFixed(2)
                                            }}
                                            m3/dt debiet air pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                            WIB</span>
                                    </div>
                                    <div class="group inline-block relative cursor-pointer"
                                        v-click-outside="closeDialogFilterDebit">
                                        <div class="flex flex-wrap content-center items-center">
                                            <button class="flex flex-wrap conent-center items-end justify-end"
                                                @click="dialog_filter_debit = !dialog_filter_debit">
                                                <div class="">
                                                    <div
                                                        class="flex items-center rounded-lg border border-gray-100 px-4 py-2">
                                                        <span v-if="filter_debit == 'last_day'">
                                                            Hari Ini
                                                        </span>
                                                        <span v-if="filter_debit == '3days'">
                                                            3 Hari Terakhir
                                                        </span>
                                                        <span v-if="filter_debit == 'last_week'">
                                                            1 Minggu Terakhir
                                                        </span>
                                                        <span v-if="filter_debit == 'last_month'">
                                                            1 Bulan Terakhir
                                                        </span>
                                                        <span v-if="filter_debit == '3months'">
                                                            3 Bulan Terakhir
                                                        </span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="shadow-lg rounded-2xl transform scale-100 absolute
                                        transition duration-150 ease-in-out origin-top w-full bg-white flex-none"
                                            v-show="dialog_filter_debit"
                                            style="z-index:1000000!important; min-width:150px">
                                            <div class=" max-h-56 overflow-auto py-4 bg-white w-full">
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_day', 'debit')">
                                                    <div class="ml-4 ">Hari Ini</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3days', 'debit')">
                                                    <div class="ml-4 ">3 Hari Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_week', 'debit')">
                                                    <div class="ml-4 ">1 Minggu Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_month', 'debit')">
                                                    <div class="ml-4 ">1 Bulan Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3months', 'debit')">
                                                    <div class="ml-4 ">3 Bulan Terakhir</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-4">
                                <div style="width: 100%; height: 300px; background-color: white;" id="chartdiv_debit">
                                </div>
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-6 rounded-2xl bg-white">
                            <div class="pt-4 px-8">
                                <div class="flex justify-between w-full">
                                    <div class="">
                                        <p class="font-bold text-base">Baterai</p>
                                        <!-- <span class="text-gray-500 text-xs">{{parseFloat(currentDevice.VBAT).toFixed(0)}} VBAT baterai pukul   {{parseDate(currentDevice.last_update,'HH.mm')}} WIB</span> -->
                                        <span class="bg-white-500 text-xs">{{ parseFloat(currentDevice.VBAT).toFixed(0)
                                            }}
                                            VBAT baterai pukul {{ parseDate(currentDevice.last_update, 'HH.mm') }}
                                            WIB</span>
                                    </div>
                                    <div class="group inline-block relative cursor-pointer"
                                        v-click-outside="closeDialogFilterBatre">
                                        <div class="flex flex-wrap content-center items-center">
                                            <button class="flex flex-wrap conent-center items-end justify-end"
                                                @click="dialog_filter_batre = !dialog_filter_batre">
                                                <div class="">
                                                    <div
                                                        class="flex items-center rounded-lg border border-gray-100 px-4 py-2">
                                                        <span v-if="filter_batre == 'last_day'">
                                                            Hari Ini
                                                        </span>
                                                        <span v-if="filter_batre == '3days'">
                                                            3 Hari Terakhir
                                                        </span>
                                                        <span v-if="filter_batre == 'last_week'">
                                                            1 Minggu Terakhir
                                                        </span>
                                                        <span v-if="filter_batre == 'last_month'">
                                                            1 Bulan Terakhir
                                                        </span>
                                                        <span v-if="filter_batre == '3months'">
                                                            3 Bulan Terakhir
                                                        </span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div class="shadow-lg rounded-2xl transform scale-100 absolute transition duration-150 ease-in-out origin-top w-full bg-white flex-none"
                                            v-show="dialog_filter_batre"
                                            style="z-index:1000000!important; min-width:150px">
                                            <div class=" max-h-56 overflow-auto py-4 bg-white w-full">
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_day', 'VBAT')">
                                                    <div class="ml-4 ">Hari Ini</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3days', 'VBAT')">
                                                    <div class="ml-4 ">3 Hari Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_week', 'VBAT')">
                                                    <div class="ml-4 ">1 Minggu Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, 'last_month', 'VBAT')">
                                                    <div class="ml-4 ">1 Bulan Terakhir</div>
                                                </div>
                                                <div class="hover:bg-white-200 py-2"
                                                    @click="changeDate(currentDevice.id, '3months', 'VBAT')">
                                                    <div class="ml-4 ">3 Bulan Terakhir</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-4">
                                <div style="width: 100%; height: 300px; background-color: white;" id="batre"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="form_lamaran" v-if="dialog" class="absolute" style="z-index:110000 !important">
                    <div class="fixed inset-0 z-50 opacity-50 bg-black"></div>
                    <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
                        <div class="bg-white w-full md:w-3/4">
                            <div class="bg-white-100 p-4 rounded rounded-b-none flex justify-between items-center">
                                <p class="text-lg font-medium">DATA PERANGAKAT</p>
                                <i class="far fa-times text-lg cursor-pointer" @click="closeDialog"></i>
                            </div>
                            <div class="bg-white w-full rounded rounded-t-none p-4 overflow-auto max-h-screen">
                                <div style="w-full">
                                    <device-component class="w-full" v-on:close="closeDialog" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div id="form_lamaran" v-if="dialog_danger" class="absolute">
                <div class="fixed inset-0 z-50 opacity-50 bg-black"></div>
                <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
                    <div class="relative mx-auto w-auto max-w-full">
                        <div class="bg-white w-full rounded p-4 overflow-auto max-h-screen">
                            <div>
                                <div class="flex justify-center text-center">
                                    <div class="text-center">
                                        <div class="font-bold">
                                            <p>{{ currentDevice.name }}</p>
                                            <span class="text-red-500 text-sm"
                                                v-if="currentDevice.real_ketinggian >= currentDevice.siaga_1">
                                                {{ parseFloat(currentDevice.real_ketinggian).toFixed(2) }} cm
                                                <br>
                                                Siaga 1</span>
                                            <span class="text-yellow-500 text-sm"
                                                v-else-if="currentDevice.real_ketinggian <= currentDevice.siaga_1 && currentDevice.real_ketinggian >= currentDevice.siaga_2">
                                                {{ parseFloat(currentDevice.real_ketinggian * 100).toFixed(2) }} cm
                                                <br>
                                                Siaga 2</span>


                                        </div>
                                        <div class="flex justify-center">
                                            <img src="/siren.svg" class="h-16" alt="">
                                        </div>
                                        Terakhir Diperbaharui
                                        <br>
                                        {{ parseDate(currentDevice.last_update) }}

                                        <div class="modal-close btn border mt-2 border-gray-300 p-1 px-4 font-semibold hover:bg-white-100
                                            cursor-pointer text-gray-500 ml-auto rounded" id="closemenu"
                                            @click="stop()">
                                            Tutup</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <audio id="backMusic">
            <source :src="require('../../assets/emergency.mp3')" type="audio/mpeg">
        </audio>



    </div>
</template>
<script>
var chartLevel, chartSignal, chartBatre, chartVsol, chartHumidity, chartAtmosfer, chartReal, chartElevasi, chartDebit
import { Carousel, Slide } from 'vue-carousel';
import { mapActions, mapGetters } from 'vuex';
import deviceComponent from '../../components/global/device.vue'
import mapComponent from '../../components/singleMap.vue'
import ApexCharts from 'apexcharts'
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import moment from 'moment'
import optionLevel from './optionLevel'
import optionBatre from './optionBatre'
import optionSignal from './optionSignal'
import optionVsol from './optionVsol'
import optionHumidity from './optionHumidity'
import optionAtmosfer from './optionAtmosfer'
import optionElevasi from './optionElevasi'
import optionReal from './optionReal'
import optionDebit from './optionDebit'
import globalMixin from '../../mixin/global'
import textLevel from '../../components/dashboard/textLevel.vue'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { root } from 'postcss';
export default {
    data() {
        return {
            is_admin: false,
            hours: 0,
            minutes: 0,
            seconds: 0,
            dialog: false,
            loadingChart: false,
            update: '',
            dataChart: [],
            batre: [],
            dataLevel: [],
            dataSignal: [],
            dataVsol: [],
            dataReal: [],
            dataBatre: [],
            dataHumidity: [],
            dataElevasi: [],
            dataAtmosfer: [],
            dataXtilt: [],
            dataYtilt: [],
            dataDebit: [],
            mute: true,
            audio2: null,
            dialog_danger: false,
            first_danger: true,

            filter_real_ketinggian: 'last_day',
            filter_CSQ: 'last_day',
            filter_humidity: 'last_day',
            filter_tekanan_atmosfir: 'last_day',
            filter_Xtilt: 'last_day',
            filter_Ytilt: 'last_day',
            filter_VSOL: 'last_day',
            filter_elevasi_muka_air: 'last_day',
            filter_debit: 'last_day',
            filter_batre: 'last_day',

            dialog_filter_real_ketinggian: false,
            dialog_filter_CSQ: false,
            dialog_filter_humidity: false,
            dialog_filter_VSOL: false,
            dialog_filter_elevasi_muka_air: false,
            dialog_filter_debit: false,
            dialog_filter_batre: false,
            dialog_filter_tekanan_atmosfir: false,
            dialog_filter_Xtilt: false,
            dialog_filter_Ytilt: false,
        }
    },
    components: {
        Carousel,
        Slide,
        deviceComponent, mapComponent, textLevel,

    },
    mixins: [globalMixin, optionLevel, optionBatre, optionSignal, optionVsol, optionHumidity, optionAtmosfer, optionReal, optionElevasi, optionDebit],
    methods: {
        ...mapActions({
            setCurrentDevice: 'device/setCurrentDevice',
        }),
        setTime() {
            setInterval(() => {
                const date = new Date()
                this.hours = date.getHours()
                this.minutes = this.checkSingleDigit(date.getMinutes())
                this.seconds = this.checkSingleDigit(date.getSeconds())
            }, 1000)
        },
        closeDialog() {
            this.dialog = false
        },
        closeDialogFilterRealKetinggian() {
            this.dialog_filter_real_ketinggian = false
        },
        closeDialogFilterVSOL() {
            this.dialog_filter_VSOL = false
        },
        closeDialogFilterElevasi() {
            this.dialog_filter_elevasi_muka_air = false
        },
        closeDialogFilterTekananAtmosfir() {
            this.dialog_filter_tekanan_atmosfir = false
        },
        closeDialogFilterXtilt() {
            this.dialog_filter_Xtilt = false
        },
        closeDialogFilterYtilt() {
            this.dialog_filter_Ytilt = false
        },
        closeDialogFilterHumidity() {
            this.dialog_filter_humidity = false
        },
        closeDialogFilterDebit() {
            this.dialog_filter_debit = false
        },
        closeDialogFilterCSQ() {
            this.dialog_filter_CSQ = false
        },
        closeDialogFilterBatre() {
            this.dialog_filter_batre = false
        },
        checkSingleDigit(digit) {
            return ('0' + digit).slice(-2)
        },

        async getData() {
            await this.axios.get('v2/device/detail/data/' + this.currentDevice.id, this.config)
                .then((ress) => {
                    this.dataChart = ress.data
                    // console.log(this.currentDevice, 'data')

                })
                .catch((err) => {
                    this.errorResponse(err)
                })
        },
        async pushData(x) {
            let label = x.last_update
            // this.dataLevel.push({
            //     x: new Date(label).getTime(),
            //     // x: parseInt(x.time),
            //     y: parseFloat(x.level).toFixed(2)
            // })
            await this.dataReal.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.real_ketinggian * 100).toFixed(2)
            })
            if (x.debit) {
                await this.dataDebit.push({
                    x: new Date(label).getTime(),
                    // x: parseInt(x.time),
                    y: parseFloat(x.debit).toFixed(4)
                })
            }
            // if (x.elevasi_muka_air) {
            await this.dataElevasi.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.elevasi_muka_air).toFixed(2),
                h: parseFloat(x.real_ketinggian * 100).toFixed(2),
                debit: parseFloat(x.debit).toFixed(4)

            })
            // }

            await this.dataVsol.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.VSOL)
            })
            await this.dataHumidity.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.HUM).toFixed(0)
            })
            await this.dataAtmosfer.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.PATM).toFixed(2)
            })
            await this.dataXtilt.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.Xtilt).toFixed(2)
            })
            await this.dataYtilt.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.Ytilt).toFixed(2)
            })
            await this.dataSignal.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.CSQ).toFixed(2)
            })
            await this.batre.push({
                x: new Date(label).getTime(),
                y: 3.85
            })

            await this.dataBatre.push({
                x: new Date(label).getTime(),
                // x: parseInt(x.time),
                y: parseFloat(x.VBAT)
            })

        },

        async updateChart() {

            await this.$set(this.optionsBatre, 'series', [
                {
                    name: 'VBAT',
                    type: 'line',
                    data: this.dataBatre
                },
                {
                    name: 'Low Batery',
                    data: this.batre,
                    type: 'area',

                }
            ])
            await this.$set(this.optionsBatre, 'colors', [
                "#1899fb", "#FF1654"
            ])

        },
        buatGrafik(dataReal, elname) {

            let root = am5.Root.new(elname);
            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true,
            }));

            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "none"
            }));
            cursor.lineY.set("visible", false);
            var xRenderer = am5xy.AxisRendererX.new(root, {});
            xRenderer.labels.template.set("minPosition", 0.01);
            xRenderer.labels.template.set("maxPosition", 0.99);

            let xAxis = chart.xAxes.push(
                am5xy.CategoryDateAxis.new(root, {
                    categoryField: "date",
                    baseInterval: {
                        timeUnit: "minute",
                        count: 30
                    },
                    renderer: xRenderer,
                    tooltip: am5.Tooltip.new(root, {}),
                })
            );
            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {})
                })
            );

            let label = 'Ketinggian Air'
            let unit = 'cm'

            switch (elname) {
                case 'chartdiv_wave':
                    label = 'Ketinggian Gelombang';
                    unit = 'm';
                    break;
                case 'chartdiv_direction':
                    label = 'Arah Gelombang';
                    unit = 'deg';
                    break;
                case 'chartdiv':
                    label = 'Ketinggian Air';
                    unit = 'cm';
                    break;
                case 'chartdiv_gsm':
                    label = 'Sig[-]';
                    unit = '';
                    break;
                case 'chartdiv_humidity':
                    label = 'Kelembapan';
                    unit = '%';
                    break;
                case 'chartdiv_vsol':
                    label = 'Voltase V';
                    unit = '';
                    break;
                case 'chartdiv_elevasi':
                    label = 'Elevasi';
                    unit = '';
                    break;
                case 'chartdiv_atmosfer':
                    label = 'Tekanan Atmosfir kPA';
                    unit = 'kPA';
                    break;
                case 'chartdiv_debit':
                    label = 'Debit Q';
                    unit = 'Q';
                    break;
                case 'chartdiv_xlilt':
                    label = 'Kemiringan Pada Sumbu X';
                    unit = 'xinc';
                    break;
                case 'chartdiv_ytilt':
                    label = 'Kemiringan Pada Sumbu X';
                    unit = 'xinc';
                    break;
                default:
                    break;
            }
            switch (elname) {
                case 'chartdiv_gsm':
                    label = 'Sig[-]'
                    break;
            }

            let series = chart.series.push(am5xy.LineSeries.new(root, {
                name: label,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "date"
            }));
            // series.columns.template.events.on("hit", function(ev) {
            //     console.log("clicked on ", ev.target);
            // }, this);

            let tooltip = series.set("tooltip", am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                keepTargetHover: true
            }));

            tooltip.label.set("text", `[bold]{categoryX.formatDate('yyyy-MM-dd HH:mm:ss')}[/] \n {name} = [bold]{valueY} ${unit}[/]`);

            series.fills.template.setAll({
                fillOpacity: 0.2,
                visible: true,
            });
            series.strokes.template.setAll({
                strokeWidth: 2,
            });
            series.fills.template.set("fill", am5.color(0xff0000));
            series.strokes.template.set("stroke", am5.color(0x0ff000));

            var datas = [];
            dataReal.forEach(element => {
                datas.push({
                    'date': element.x,
                    'value': parseFloat(element.y)
                });
            });

            // Add export menu
            var exporting = am5plugins_exporting.Exporting.new(root, {
                menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                dataSource: data,
                numericFields: ["value", "value2"],
                numberFormat: "#,###.00"
            });

            var data = datas;
            series.data.setAll(data);
            xAxis.data.setAll(data);
            series.appear(1000);
            chart.appear(1000, 100);


            return chart;
        },
        async levelChart(update = false) {
            if (update) {

                while (am5.registry.rootElements.length > 0) {

                    am5.array.each(am5.registry.rootElements, function (root) {
                        if (root === undefined) {
                            return;
                        }
                        root.dispose();
                    })
                }
            }


            this.batre = this.dataChart.batre
            this.dataBatre = this.dataChart.dataBatre
            this.dataSignal = this.dataChart.dataSignal
            this.dataVsol = this.dataChart.dataVsol
            this.dataReal = this.dataChart.dataReal
            this.dataHumidity = this.dataChart.dataHumidity
            this.dataElevasi = this.dataChart.dataElevasi
            this.dataAtmosfer = this.dataChart.dataAtmosfer
            this.dataXtilt = this.dataChart.dataXtilt
            this.dataYtilt = this.dataChart.dataYtilt
            this.dataDebit = this.dataChart.dataDebit

            if (['PTM5360', 'PTM6022', 'PTM0001', 'PTM6022', 'PTM5311'].includes(this.currentDevice.code)) {

                const data_wave = [
                    { x: 1711945800000, y: 1.3 },
                    { x: 1711953000000, y: 1.3 },
                    { x: 1711965800000, y: 1.4 },
                    { x: 1711975800000, y: 1.4 },
                    { x: 1711985800000, y: 1.4 },
                    { x: 1711995800000, y: 1.4 },
                    { x: 1712005800000, y: 1.5 },
                    { x: 1712205800000, y: 0.3 },
                    { x: 1712305800000, y: 0.3 },
                    { x: 1712505800000, y: 0.3 },
                    { x: 1712605800000, y: 0.3 },
                    { x: 1712805800000, y: 1.3 },

                ];
                const data_direction = [
                    { x: 1711945800000, y: 70.21 },
                    { x: 1711953000000, y: 75.31 },
                    { x: 1711965800000, y: 79.33 },
                    { x: 1711975800000, y: 78.44 },
                    { x: 1711985800000, y: 80.70 },
                    { x: 1711995800000, y: 77.45 },
                    { x: 1712005800000, y: 74.55 },
                    { x: 1712205800000, y: 76.33 },
                    { x: 1712305800000, y: 75.36 },
                    { x: 1712505800000, y: 76.39 },
                    { x: 1712605800000, y: 72.31 },
                    { x: 1712805800000, y: 71.31 },

                ];
                this.buatGrafik(data_wave, 'chartdiv_wave');
                this.buatGrafik(data_direction, 'chartdiv_direction');
            }



            document.querySelector("#batre").innerHTML = ''


            this.buatGrafik(this.dataReal, 'chartdiv');
            this.buatGrafik(this.dataSignal, 'chartdiv_gsm');
            this.buatGrafik(this.dataVsol, 'chartdiv_vsol');
            this.buatGrafik(this.dataHumidity, 'chartdiv_humidity');
            this.buatGrafik(this.dataElevasi, 'chartdiv_elevasi');
            this.buatGrafik(this.dataAtmosfer, 'chartdiv_atmosfer');
            // this.buatGrafik(this.dataXtilt, 'chartdiv_xlilt');
            if (this.isUserAdmin) {
            this.buatGrafik(this.dataXtilt, 'chartdiv_xlilt');
            }
            if (this.isUserAdmin) {
            this.buatGrafik(this.dataYtilt, 'chartdiv_ytilt');
            }
            // this.buatGrafik(this.dataYtilt, 'chartdiv_ytilt');
            this.buatGrafik(this.dataDebit, 'chartdiv_debit');



            await this.updateChart()


            chartBatre = new ApexCharts(document.querySelector("#batre"), this.optionsBatre);

            await chartBatre.render();



            if (this.currentDevice.real_ketinggian >= this.currentDevice.siaga_2) {
                this.dialog_danger = true
                if (this.first_danger == true) {
                    this.play()
                    this.first_danger = false
                }

            }



        },

        async createdChart(update) {
            await this.getData()
            await this.levelChart(update)
        },

        play() {

            this.audio2.play();
            this.audio2.loop = true;
            this.mute = false;

        },
        stop() {
            this.dialog_danger = false
            this.audio2.pause();
            this.mute = true;
        },

        async changeDate(id, filter, param) {

            this.dialog_filter_real_ketinggian = false

            await this.axios.get(`v2/device_param/get-current-param?device_id=${id}&param=${param}&filter=${filter}`, this.config)
                .then((ress) => {
                    console.log("res", ress)
                    if (param == 'real_ketinggian') {
                        this.filter_real_ketinggian = filter
                        this.dataReal = ress.data.data
                        am5.array.each(am5.registry.rootElements, function (root) {
                            if (root === undefined) {
                                return
                            }

                            if (root.dom.id == 'chartdiv') {
                                root.dispose();
                            }
                        });

                        this.buatGrafik(this.dataReal, 'chartdiv');
                    } else if (param == 'CSQ') {
                        this.filter_CSQ = filter
                        this.dataSignal = ress.data.data
                        console.log(this.dataSignal)
                        am5.array.each(am5.registry.rootElements, function (root) {
                            if (root === undefined) {
                                return
                            }

                            if (root.dom.id == 'chartdiv_gsm') {
                                root.dispose();
                            }
                        });

                        this.buatGrafik(this.dataSignal, 'chartdiv_gsm');

                    } else if (param == 'VSOL') {
                        this.filter_VSOL = filter
                        this.dataVsol = ress.data.data
                        console.log(this.dataVsol)
                        am5.array.each(am5.registry.rootElements, function (root) {
                            if (root === undefined) {
                                return
                            }
                            if (root.dom.id == 'chartdiv_vsol') {
                                root.dispose();
                            }
                        });

                        this.buatGrafik(this.dataSignal, 'chartdiv_vsol');

                    } else if (param == 'debit') {
                        this.filter_debit = filter
                        this.dataDebit = ress.data.data
                        am5.array.each(am5.registry.rootElements, function (root) {
                            if (root === undefined) {
                                return
                            }
                            if (root.dom.id == 'chartdiv_debit') {
                                root.dispose()
                            }
                        })
                        this.buatGrafik(this.dataReal, 'chartdiv_debit');

                    } else if (param == 'HUM') {
                        this.filter_humidity = filter
                        this.dataHumidity = ress.data.data
                        am5.array.each(am5.registry.rootElements, function (root) {
                            if (root === undefined) {
                                return
                            }
                            if (root.dom.id == 'chartdiv_humidity') {
                                root.dispose()
                            }
                        })
                        this.buatGrafik(this.dataHumidity, 'chartdiv_humidity');
                    } else if (param == 'PATM') {
                        this.filter_tekanan_atmosfir = filter
                        this.dataAtmosfer = ress.data.data
                        am5.array.each(am5.registry.rootElements, function (root) {
                            if (root === undefined) {
                                return
                            }
                            if (root.dom.id == 'chartdiv_atmosfer') {
                                root.dispose()
                            }
                        })
                        this.buatGrafik(this.dataAtmosfer, 'chartdiv_atmosfer');
                    } else if (param == 'Xtilt') {
                        this.filter_Xtilt = filter
                        this.dataXtilt = ress.data.data
                        am5.array.each(am5.registry.rootElements, function (root) {
                            if (root === undefined) {
                                return
                            }
                            if (root.dom.id == 'chartdiv_xlilt') {
                                root.dispose()
                            }
                        })
                        this.buatGrafik(this.dataXtilt, 'chartdiv_ytilt');
                    } else if (param == 'Ytilt') {
                        this.filter_Ytilt = filter
                        this.dataYtilt = ress.data.data
                        am5.array.each(am5.registry.rootElements, function (root) {
                            if (root === undefined) {
                                return
                            }
                            if (root.dom.id == 'chartdiv_ytilt') {
                                root.dispose()
                            }
                        })
                        this.buatGrafik(this.dataYtilt, 'chartdiv_ytilt');
                    } else if (param == 'elevasi_muka_air') {
                        console.log("filter", filter)
                        this.filter_elevasi_muka_air = filter
                        this.dataAtmosfer = ress.data.data
                        am5.array.each(am5.registry.rootElements, function (root) {
                            if (root === undefined) {
                                return
                            }
                            if (root.dom.id == 'chartdiv_elevasi') {
                                root.dispose()
                            }
                        })
                        this.buatGrafik(this.dataAtmosfer, 'chartdiv_elevasi');
                    } else if (param == 'debit') {
                        console.log("filter", filter)
                        this.filter_debit = filter
                        this.dataDebit = ress.data.data
                        am5.array.each(am5.registry.rootElements, function (root) {
                            if (root === undefined) {
                                return
                            }
                            if (root.dom.id == 'chartdiv_debit') {
                                root.dispose()
                            }
                        })
                        this.buatGrafik(this.dataDebit, 'chartdiv_debit');
                    } else if (param == 'VBAT') {

                        this.filter_batre = filter
                        this.dataBatre = ress.data.data
                        this.batre = ress.data.batre
                        document.querySelector("#batre").innerHTML = ''
                        this.updateChart()
                        chartBatre = new ApexCharts(document.querySelector("#batre"), this.optionsBatre);
                        chartBatre.render();
                    }
                })
                .catch((err) => {
                    this.errorResponse(err)
                })

        },
    },
    computed: {
        ...mapGetters({
            device: 'device/showDevice',
            currentDevice: 'device/currentDevice',
            user: 'auth/user',
        }),
        isUserAdmin() {
            console.log("is admin", this.user)
            return this.user && this.user.role_id === 32;
        },
    },
    async created() {
        if (!this.currentDevice) {
            this.$router.push('/monitor');
        }
        await this.createdChart(false);
        this.is_admin = this.isUserAdmin;
    },
    mounted() {
        this.setTime()
        this.audio2 = document.getElementById('backMusic');
        this.sockets.subscribe('updatedatachart', async (data) => {

            if (data.id == this.currentDevice.id) {
                this.currentDevice.last_update = data.last_update
                this.currentDevice.CSQ = data.CSQ
                this.currentDevice.real_ketinggian = data.real_ketinggian
                this.currentDevice.VBAT = data.VBAT
                await this.pushData(data)
                await chartReal.updateSeries([{ data: this.dataReal }])
                await chartDebit.updateSeries([{ data: this.dataDebit }])
                await chartElevasi.updateSeries([{ data: this.dataElevasi }])
                await chartSignal.updateSeries([{ data: this.dataSignal }])
                await chartVsol.updateSeries([{ data: this.dataVsol }])
                await chartHumidity.updateSeries([{ data: this.dataHumidity }])
                await chartAtmosfer.updateSeries([{ data: this.dataAtmosfer }])

                chartBatre.updateSeries([
                    {
                        name: 'VBAT',
                        type: 'line',
                        data: this.dataBatre
                    },
                    {
                        name: 'Low Batery',
                        data: this.batre,
                        type: 'area',

                    }
                ])
            }

        });
        // this.play()
    },
    watch: {
        currentDevice: async function (newQuestion, oldQuestion) {
            await this.createdChart(true)
        }
    },
    beforeDestroy() {
        this.sockets.unsubscribe('updatedatachart');

        clearInterval(this.setTime)
        this.audio2.pause();
        this.mute = true;
    }

}
</script>
